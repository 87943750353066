<div>
    <div class="detail-wrapper"
        [ngClass]="{'restrict-height' : showImageCarousel === true || errorOnDownload ===true || openInstructions}">
        <div class="progress-bar" *ngIf="isLoading">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="img-carousel-container" *ngIf="showImageCarousel"
            [ngStyle]="{'display':showImageCarousel === true ? 'block' : 'none' }">
            <div class="img-carousel" (click)="showImageCarousel = false">
                <div *ngFor="let screenshot of appDetails?.screens">
                    <img [src]="screenshot.path" alt="back_btn" />
                </div>
            </div>
        </div>
        <div class="error-page-container" *ngIf="errorOnDownload"
            [ngStyle]="{'display':errorOnDownload === true ? 'block' : 'none' }">
            <div class="error-wrapper">
                <div class="error-body">
                    <div class="robot-container">
                        <img height="159px" width="211px" src="../../assets/images/Aliennew@2x.png" alt="back_btn" />
                    </div>
                    <div class="rectangle-hand">
                        <img height="49px" width="36px" src="../../assets/images/Rectangle 3366@2x.png"
                            alt="back_btn" />
                    </div>
                    <div class="blue-background">
                        <img src="../../assets/images/bummer back.png" alt="back_btn" />
                    </div>
                    <div class="error-msg-container">
                        <p class="bummer-text">Let me in!</p>
                        <span>Your session expired, please <br>log in again to use the app. </span>
                    </div>
                    <div class="error-footer">
                        <div class="error-leaf-left">
                            <img src="../../assets/images/Group 6817@2x.png" alt="footer" />
                        </div>
                        <img src="../../assets/images/footer.png" alt="footer" />
                        <div class="error-leaf-right">
                            <img src="../../assets/images/Group 6816@2x.png" alt="footer" />
                        </div>
                    </div>
                </div>
                <div class="cta">
                    <div class="cat-btn" (click)="sessionExpired()">
                        <span>Take me to Login</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="instruction-panel" *ngIf="openInstructions" (click)="openInstructions= false">
            <div class="inst-container">
                <p> Bear in mind!</p>
                <div class="inst-area">
                    <pre>{{(appDetails?.details?.instruction?.trim()) ? appDetails?.details?.instruction : message.DEFAULT_INSTRUCTIONS }}</pre>
                </div>
                <div class="cta">
                    <a class="cat-btn inst-install-btn" [href]="downloadLink" (click)="downloadApp($event)">
                        <span>Proceed to install</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="detail-header">
            <div class="back-btn">
                <div class="back-btn-arrow" (click)="backToHomeScreen()">
                    <img src="../../assets/images/Icon material-arrow_left.png" alt="back_btn" />
                </div>
                <!-- <div class="search-glass">
                    <img src="../../assets/images/icon_search.png" alt="back_btn" />
                </div> -->
            </div>
            <div class="app-detail">
                <div class="recent-app-logo">
                    <img [src]="appDetails?.logo" alt="recent_app_banner" />
                </div>
                <div class="recent-app-details">
                    <span class="recent-app-details-name">{{appDetails?.name}}</span>
                    <span class="recent-app-details-size" *ngIf="appDetails?.lastUpdated">Updated on
                        {{formatDate(appDetails?.lastUpdated?._seconds)}}</span>
                    <span class="recent-app-details-size">Version {{appDetails?.version}}</span>
                </div>
            </div>
            <div class="install-btn-container">
                <a (click)="openInstructions=true"><span>{{installUpdateBtn}}</span></a>
            </div>
            <div class="app-video-container">
                <video width="92%" height="190" [poster]="appDetails?.banner.path" controls  playsinline
                    #audioplayersource>
                    <source [src]="videoUrl" type='video/mp4'>
                </video>
                <!-- <img *ngIf = "videoUrl === ''" [src] = "appDetails?.banner?.path" width="92%" height="190px" /> -->
            </div>
            <div class="btn-carousel-container" *ngIf="appDetails?.screens">
                <div class="btn-carousel" (click)="showImageCarousel = true">
                    <div *ngFor="let screenshot of appDetails?.screens">
                        <img [src]="screenshot.path" alt="back_btn" />
                    </div>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="whats-new-section" *ngIf="appDetails?.details?.whatsNew?.trim()">
                <p> What's new </p>
                <p>{{appDetails?.details?.whatsNew}}</p>
            </div>
            <div class="about-app-section">
                <p> About this App </p>
                <pre>{{appDetails?.details?.description}}</pre>
            </div>
            <hr>
            <div class="other-app-section">
                <div class="forward-btn">
                    <div class="other-app-title">
                        <p>Check our other apps</p>
                    </div>
                    <div class="forward-btn-arrow" (click)="scrollRight()">
                        <img src="../../assets/images/Icon material-arrow_right.png" alt="back_btn" />
                    </div>
                </div>
                <div>
                    <div #widgetsContent class="other-app-carousel">
                        <div *ngFor="let app of otherAppCarouselData">
                            <a (click)="navigateToApps(app)"><img width="99px" height="99px" [src]='app?.logo'
                                    alt="better_me" /></a>
                            <span class="app-name">{{app?.name}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
        </div>
        <div class="details-footer">
            <div class="app-info">
                <p> Information </p>
                <div class="info-section">
                    <div><span>Version</span><span>{{appDetails?.version}}</span></div>
                    <div>
                        <span>Update</span><span>{{appDetails?.lastUpdated ? formatDate(appDetails?.lastUpdated?._seconds) : '-'}}</span>
                    </div>
                    <div><span>Size</span> <span>{{formatBytes(appDetails?.size , 1)}}</span></div>
                    <div><span>Languages</span><span>English</span></div>
                    <div><span>Launched on</span><span>{{formatDate(appDetails?.releasedOn?._seconds)}}</span></div>
                </div>
            </div>
            <div class="footer-background"></div>
        </div>
    </div>
</div>