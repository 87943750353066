import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../login.service';
import { environment } from '../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { message } from '../constant/message';

@Component({
  selector: 'app-app-detail',
  templateUrl: './app-detail.component.html',
  styleUrls: ['./app-detail.component.css']
})
export class AppDetailComponent implements OnInit {
  @ViewChild('widgetsContent') widgetsContent: ElementRef;
  @ViewChild('audioplayersource') audioplayer: ElementRef;
  showImageCarousel: boolean = false;
  errorOnDownload: boolean = false;
  downloadLink: any;
  isAndroid: any = false;
  videoUrl: string = "";
  isLoading: boolean = false;
  openInstructions: boolean = false;
  installUpdateBtn: string = 'Install App';
  message: any = message;

  constructor(private router: Router, private loginService: LoginService, private sanitizer: DomSanitizer, private route: ActivatedRoute) {
    this.isAndroid = /android/i.test(navigator.userAgent);
  }

  otherAppCarouselData = message.appList;
  appDetails: any;

  async ngOnInit() {
    this.isLoading = true;
    this.route.queryParams.subscribe((qParams) => {
      this.installUpdateBtn = (qParams['action']) ? "Update App" : 'Install App'
    })
    this.route.params.subscribe(async (params) => {
      let id = params['id'];
      window.scrollTo(0, 0);
      try {
        let resp: any = await this.loginService.fetchAppDetails(id);
        if (resp.data && resp.errorCode == 0) {
          this.appDetails = resp.data;
          this.videoUrl = (this.appDetails.video) ? this.appDetails.video.path : '';
          this.audioplayer.nativeElement.load();
          this.downloadLink = this.sanitizer.bypassSecurityTrustUrl(`${environment.downloadLink}/${this.appDetails.id}/manifest.plist`);
          let data: any = await this.loginService.fetchAppList();
          this.otherAppCarouselData = data.data.apps;
          this.isLoading = false;
        } else if (resp.errorCode == 4013) {
          await this.loginService.refreshTokenGenerater();
          this.ngOnInit();
        } else {
          this.isLoading = false;
          this.backToHomeScreen();
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    });
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  backToHomeScreen() {
    this.router.navigate(['/home']);
  }

  scrollRight() {
    this.widgetsContent.nativeElement.scrollLeft += 150;
  }

  downloadApp(evt) {
    evt.stopPropagation()
    setTimeout(() => {
      this.openInstructions = false;
      this.installUpdateBtn = 'Installing';
    }, 200);
  }

  navigateToApps(app) {
    if (/android/i.test(navigator.userAgent)) {
      window.location.href = message.GLOBANT_ANDROID_APPS_URL;
    } else {
      if (app.releaseMode == 'INTERNAL')
        this.router.navigate(['/appDetails', app.id]);
      else {
        window.location.href = app.appStoreLink;
      }
    }
  }

  sessionExpired() {
    this.errorOnDownload = false;
    this.loginService.logout();
    window.localStorage.removeItem('userinfo');
    this.router.navigate(['/login'])
  }

  formatDate(date) {
    let tempDate = new Date(date * 1000)
    let month = tempDate.toLocaleString('default', { month: 'short' });
    let day = tempDate.getDate();
    let year = tempDate.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
